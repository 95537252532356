<template>
  <PageWithLayout>
    <Search
      :searchDataList.sync="viewModel.searchData"
      @onSearch="viewModel.onSearch()"/>
    <!-- <div class="box_search_result">
      <p>윤엄마, 010-1234-1234로 신청된 신청서 내역</p>
    </div> -->
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="rowId => viewModel.onClickRow(rowId)">
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import CardApplyItem from '@/views/service/card/view/CardApplyItem';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// viewModel
import CardApplySearchViewModel from '@/views/service/card/viewModel/CardApplySearchViewModel'

export default {
  name:'CardApplyList',
  components:{
    PageWithLayout,
    Search,
    CardApplyItem,
    Board
  },
  data(){
    return{
      viewModel: new CardApplySearchViewModel(),
    }
  },
}
</script>

<style scoped>
.box_search_result{padding:10px 20px;border-top:1px solid #222;border-bottom:1px solid #ccc;background-color:#fff}
</style>