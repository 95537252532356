<template>
    <div class="item_apply">
      <div class="head_apply">
        <strong class="tit_apply">신청서 ID : UEHGBDJ</strong>
      </div>
      <div class="body_apply">
        <div class="group_dl group_dl_2">
          <dl>
            <dt>신청인ID</dt>
            <dd>{{ rowData.apply_id }}</dd>
          </dl>
          <dl class="item_mark">
            <dt>신청인 이름</dt>
            <dd>{{ rowData.apply_name }}</dd>
          </dl>
          <dl class="item_mark">
            <dt>신청인 전화번호</dt>
            <dd>{{ rowData.apply_phone_number }}</dd>
          </dl>
          <dl>
            <dt>관계</dt>
            <dd>{{ rowData.apply_relationship }}</dd>
          </dl>
          <dl>
            <dt>신청확정일시</dt>
            <dd>{{ rowData.apply_at | convertDateFormat }}</dd>
          </dl>
        </div>
        <div class="bar_divide"></div>
        <div class="group_dl group_dl_2">
          <dl>
            <dt>사용인ID</dt>
            <dd>{{ rowData.user_id || '미정' }}</dd>
          </dl>
          <dl>
            <dt>사용인 이름</dt>
            <dd>{{ rowData.user_name || '미정' }}</dd>
          </dl>
          <dl>
            <dt>배송완료일시</dt>
            <dd>{{ rowData.delivery_at | convertDateFormat }}</dd>
          </dl>
        </div>
        <div class="bar_divide"></div>
        <div class="group_dl group_dl_2">
          <dl>
            <dt>발급구분</dt>
            <dd>{{ rowData.apply_type | convertIdToText('card_type','','미확인') }}</dd>
          </dl>
          <dl>
            <dt>수수료</dt>
            <dd>{{ rowData.apply_fee }}</dd>
          </dl>
          <dl>
            <dt>상태</dt>
            <dd>{{ rowData.status | convertIdToText('card_apply_status') }}</dd>
          </dl>
        </div>
        <div class="bar_divide"></div>
        <div class="group_dl group_dl_2">
          <dl>
            <dt>우편번호</dt>
            <dd>{{ rowData.address_code }}</dd>
          </dl>
          <dl>
            <dt>주소</dt>
            <dd>{{ rowData.address1 }}</dd>
          </dl>
          <dl>
            <dt>상세주소</dt>
            <dd>{{ rowData.address2 }}</dd>
          </dl>
        </div>
        <div class="bar_divide"></div>
        <Board
          :boardData="boardData"
          :searchParams.sync="searchParams"
          :dataList="rowData.cs_list"
          :paginationData="paginationData"
          @onClickRow="rowId => onClickRow(rowId)">
          <template v-slot:headRight>
            <Button
              btnSize="small"
              btnStyle="secondary"
              text="일치추가"/>
          </template>
        </Board>
      </div>
    </div>
</template>

<script>
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
export default {
  name: 'CardApplyItem',
  components:{
    Board,
    Button
  },
  props:{
    boardData: Object,
    rowData: Object
  },
  mounted(){
    this.paginationData.totalCount = this.rowData.cs_list.length
  },
  data(){
    return{
      searchParams:{
        page:0,
        pageSize:10,
        direction: 'DESC'
      },
      paginationData: {
        totalCount:0,
        totalPage:1
      }
    }
  },
  methods:{
    onClickRow(){

    }
  }
}
</script>

<style scoped>
.item_apply{margin-top:40px;border-radius:10px;background-color:#fff;box-shadow:2px 4px 4px 0 rgba(0,0,0,.1)}
.item_apply .head_apply{padding:18px 20px 14px;border-radius:10px 10px 0 0;background-color:#3DBF6980}
.item_apply .head_apply .tit_apply{font-weight:700;font-size:18px;line-height:20px;color:#333}
.item_apply .body_apply{padding:14px 0 20px;border-radius:0 0 10px 10px}
/* .item_apply .body_apply dl{display:inline-block;vertical-align:top;word-wrap:break-word;word-break:keep-all}
.item_apply .body_apply dd{margin-top:4px;font-weight:600;font-size:18px;line-height:24px;color:#222} */

.group_dl{padding:0 10px}
.group_dl dl{display:inline-block;padding:4px 10px;vertical-align:top;word-wrap:break-word;word-break:keep-all;box-sizing:border-box}
.group_dl dt,
.group_dl dd{line-height:24px;vertical-align:top}
.group_dl dt{width:140px}
.group_dl dd{display:inline-block;max-width:140px;font-weight:600;font-size:14px;line-height:24px;color:#222;vertical-align:top}
.item_mark dd{padding:0 4px;border-radius:4px;font-weight:700;background-color:#FFD82A80;color:#222}
/* .group_dl_2 dl{width:50%}
.group_dl_3 dl{width:33.33%}
.group_dl_4 dl{width:25%}
.group_dl .dl_full{width:100%} */

.bar_divide{margin-top:10px;padding-bottom:10px;border-top:1px dashed #ccc}

.board_comm{padding:0 20px}
.board_comm::v-deep .tbl_comm .tbl_top .tit_tbl{font-size:18px}
.board_comm::v-deep .tbl_comm .tbl_top .tit_tbl + .desc_tbl{margin-left:6px;font-size:14px}
.board_comm::v-deep .area_empty{padding:18px 0 17px}
.board_comm::v-deep .page_comm{padding-top:20px}

@media (prefers-color-scheme: dark) {
  .item_apply{background-color:#222}
  .group_dl dd{color:#fff}
}

/* .area_user{display:flex;overflow:hidden;padding:20px 20px 20px}
.area_user .box_apply{flex:1 1;max-width:calc(50% - 10px);padding:14px 10px;border-radius:20px;box-sizing:border-box;box-shadow:0 0 6px 0 rgba(0,0,0,.3)}
.group_dl_half dl{display:block;width:100%}
.group_dl_half dt,
.group_dl_half dd{display:inline-block;width:50%;line-height:24px;vertical-align:top;box-sizing:border-box}
.group_dl_half dt{text-align:right;padding-right:20px}
.area_user .box_apply_right{position:relative;margin-left:20px} */
/* .area_user .box_apply_right:after{position:absolute;top:50%;left:-20px;width:20px;height:2px;margin-top:-4px;background-color:#ccc;content:''} */
</style>