import router from '@/router';

export default class CardApplySearchViewModel {
  constructor() {
    // this.tabData = {
    //   dataList: [
    //     { id: 'INFO', text:'신청인 정보로 검색' },
    //     { id: 'ID', text:'신청인 ID로 검색' },
    //   ],
    //   option:{
    //     isCount: false
    //   },
    //   value:'INFO'
    // }
    this.searchData = [
      {
        title:'신청인 이름',
        type: 'Input',
        maxWidth:"300px",
        isFull:true,
        value:''
      },
      {
        title:'신청인 전화번호',
        type: 'Input',
        maxWidth:"300px",
        valueType:'tel',
        isFull:true,
        value:''
      },
      {
        title:'신청인 ID',
        type: 'Input',
        maxWidth:"300px",
        isFull:true,
        value:''
      },
    ]
    this.boardData = {
      title:'카드 신청인 목록',
      drawDataList: [
        // {
        //   title:'No.',
        //   width:'60px',
        //   value:'id',
        //   isSorting: true
        // },
        {
          title:'신청인ID',
          width:'110px',
          value:'apply_id',
        },
        {
          title:'신청인 이름',
          value:'apply_name',
          isAlignLeft: true
        },
        {
          title:'신청인 전화번호',
          value:'apply_phone_number',
          isAlignLeft: true
        },
      ],
      markData : [
        {
          id: 'apply_name', value: this.searchData[0].value,
        },
        {
          id: 'apply_phone_number', value: this.searchData[1].value,
        },
        {
          id: 'apply_id', value: this.searchData[2].value,
        }
      ],
      option:{
        isTotal: true,
        rowIdValue: 'apply_id'
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  init(){
    this.getData();
  }
  onClickRow(rowId){
    router.push({ name: 'SERVICE_CARD_DETAIL', params: { id : rowId } });
  }
  getData(){
    // this.dataList = [
    //   { id:'1', initDate:'2022-04-11', csContent:'자녀 카드를 신청했는데 오지 않습니다', csManager:'박하영', status:'ING', completeDate:'-' },
    // ];
    this.paginationData.totalCount = 3;
    this.paginationData.totalPage = 1;
    const resultData = [
      {
        apply_id:'ISDNF8G',
        apply_name: '윤엄마',
        apply_phone_number: '010-1234-5678',
        apply_relationship: '그룹',
        apply_at: '2022-06-27T12:05:01',
        user_id:'PSOND7T',
        user_name:'정*리',
        delivery_at:'',
        apply_type:'NEW',
        apply_fee:'무료',
        status:'REGISTERED',
        address_code:'123558',
        address1:'경기도 성남시 분당구 성남대로331번길 8',
        address2:'507호',
        cs_list: [
          {
            id:1,
            cearted_at:'2022-04-11T12:05:01',
            title:'자녀 카드를 신청했는데 오지 않습니다',
            cs_manager_name:'박하영',
            status:'COMPLETE',
            complete_at:'2022-04-11T15:05:01',
          }
        ],
      },
      {
        apply_id:'ISDNF9H',
        apply_name: '윤엄마',
        apply_phone_number: '010-1234-5678',
        apply_relationship: '그룹',
        apply_at: '2022-06-27T12:05:01',
        user_id:'',
        user_name:'',
        delivery_at:'',
        apply_type:'',
        apply_fee:'무료',
        status:'PENDING',
        address_code:'123558',
        address1:'경기도 성남시 분당구 성남대로331번길 8',
        address2:'507호',
        cs_list: [],
      },
    ]
    this.dataList = resultData;
  }
  onSearch(){
    this.getData();
    // router.push({ name:'SERVICE_CARD_LIST' })
  }
}